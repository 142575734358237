import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";


function PrivacyPolicy() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Privacy Policy">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Privacy Policy"
                    Subheader="Privacy Policy"
                    bgimg={`${rpdata?.stock?.[0]}`}
                    // para cambiar el svg del header busquemos en la carpeta css y buscamos el archivo de svgStyles
                    // los estilo de svg para el header empiezan svgTranspareHeader seguido de un numero
                    svgClass='svgTranspareHeader2'
                />
                <div className="w-full py-12 text-center flex flex-col items-center justify-center">
                    <h1 className="text-5xl font-bold text-gray-800 border-b-8 border-black">Legal Notices for Movers On The Way & Storage LLC</h1>
                    <div className="w-4/5 bg-slate-200 rounded-lg mt-10 text-start p-14 space-y-4">
                        <p className="text-3xl font-bold text-gray-900">Disclaimer:</p>
                        <p className="text-base font-medium text-gray-800">Welcome to Movers On The Way & Storage LLC's website. By accessing or using our website, you agree to comply with and be bound by the following disclaimer. The information contained on this website is for general information purposes only. While we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
                        <p className="text-3xl font-bold text-gray-900">Privacy Policy:</p>
                        <p className="text-base font-medium text-gray-800">
                            Your privacy is important to us. This privacy policy outlines how Movers On The Way & Storage LLC collects, uses, maintains, and discloses information collected from users (each, a "User") of our website. This policy applies to the website and all products and services offered by Movers On The Way & Storage LLC. We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, place an order, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our website. Users may be asked for, as appropriate, name, email address, mailing address, phone number, and credit card information. Users may, however, visit our website anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain website-related activities. We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our website.
                        </p>
                        <p className="text-3xl font-bold text-gray-900">Urgent Notice:</p>
                        <p className="text-base font-medium text-gray-800">It is imperative that we promptly apply these legal notices on our website to ensure compliance with legal requirements and to safeguard the interests of our users and our company. Failure to implement these notices may not only expose us to legal risks but also allow our competitors to gain an advantage in attracting potential clients. We are committed to providing transparency, reliability, and security to our users, and these legal notices are essential components of that commitment.</p>

                        <div className="w-full pt-10 text-center border-t-4 border-black">
                            <p className="text-lg font-bold text-gray-900">Feel free to adjust and customize this content further to suit your website's specific needs and requirements.</p>
                        </div>
                    </div>
                </div>

            </div>
        </BaseLayout>
    );
}

export default PrivacyPolicy;
